import React, { useState, useEffect } from "react"
//Import the History.css
import HistoryStyles from "./History.module.css";

const History = () =>  {
// Client-side Runtime Data Fetching
const [title, setTitle] = useState('')
const [content, setContent] = useState('')
const [source, setSource] = useState([])
useEffect(() => {
  // get data from facts.json
  fetch(`/facts.json`)
    .then(response => response.json()) // parse JSON from request
    .then(resultData => {
        const resultDataCount = resultData.facts.length;
        const randomNumber = Math.floor(Math.random() * resultDataCount);
      setTitle(resultData.facts[randomNumber].title);
      setContent(resultData.facts[randomNumber].content);
      setSource(resultData.facts[randomNumber].sources)
    }) // set data
}, [])

return (
<div id="History" className={"row"}>
    <div className={HistoryStyles.leftContainer + " col-lg-4"}>
        <div className={HistoryStyles.left}>
            <h2 className={HistoryStyles.mainTitle + " colorLight"}>History Fact</h2>
            <img className={HistoryStyles.heroImage} src={'/history-facts.svg'} alt="Person who reads" />
        </div>
    </div>
    <div className={"col-lg-8"}>
        <div className={HistoryStyles.right}>
        <h4 className={HistoryStyles.factTitle} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={HistoryStyles.factContent} dangerouslySetInnerHTML={{ __html: content }} />
        <h5 className={HistoryStyles.factSourcesTitle}>Sources</h5>
        <div className={HistoryStyles.factSources}><ul>{source.map((node) => (<li key={node.id}><a className={HistoryStyles.factSourcesLink} href={node.url} target="_blank" rel="noopener noreferrer">{node.name}</a></li> ))}</ul></div>
        </div>
    </div>    
</div>
)
} 
export default History;