import React from "react"
//Import the About.css
import AboutStyles from "./About.module.css";

const About = () => (
<div id="About" className={"row"}>
    <div className={AboutStyles.leftContainer + " col-lg-4"}>
        <div className={AboutStyles.left}>
            <h2 className={AboutStyles.mainTitle + " colorLight"}>About</h2>
            <img className={AboutStyles.heroImage} src={'/about-me.svg'} alt="Person who reads" />
        </div>
    </div>
    <div className={"col-lg-8"}>
        <div className={AboutStyles.right}>
            <p className={AboutStyles.chapterQuote + " fontItalic"}><strong>Still round the corner there may wait a new road or a secret gate</strong></p>
            <p>I started my work as a Web Developer with Olawur.com in 2016. Olawur.com is a project born from my passion for Scandinavian culture, mixed with the knowledge of every piece that forms the professional figure of a Full Stack Web Developer. The name of the project - Olawur - comes from the Scandinavian name Olavur, where the "V" has been switched to "W" to tie the Nordic culture with the World Wide Web. My aim as a Web Developer is to develop websites suitable for everyone: from small business to larger projects. My ultimate goal is to use my web development knowledge to innovate the way people and business interact with others.</p>
            <h3 className={AboutStyles.chapterTitle}>Creativity and Development</h3>
            <p className={AboutStyles.chapterQuote + " fontItalic"}><strong>And though I oft have passed them by, a day will come at last when I</strong></p>
            <p>As an independent writer and someone with a degree in Literature and Linguistics, my approach to creativity in web development is rather peculiar. I am used to, and I like to, think outside the box, I like to innovate, to question rules and ideas, and I strive to give new answers to old and new problems. What made me realize that I wanted to have a career in web development is, on one hand, all the great things you can achieve with technology and, on the other hand, the impact that technology had in contemporary society. </p>
            <p>I have studied anthropology and sociology during my university days, and being in the tech world has really helped me understand what a powerful tool technology is. Like all the powerful tools, though, technology needs to be used in an intelligent way: I want to use it to connect people, I want to be part of a positive change in people lives, maybe not by developing something that will bring the world to a utopia, but if I can just only bring a business closer to its customers or help someone spreads their message, I will be more than happy to have used technology positively.</p>
            <p>Being a Web Developer for me doesn't mean being a genius of a programmer, but it means being able to create intelligent projects that are innovative, secure and accessible; because we shouldn't leave anyone behind.</p>
            <h3 className={AboutStyles.chapterTitle}>Reading and Studying</h3>
            <p className={AboutStyles.chapterQuote + " fontItalic"}><strong>Shall take the hidden paths that run West of the Moon, East of the Sun.</strong></p>
            <p>To be a writer, one has to be an avid reader first. Reading has accompanied me during my life since I was five years old and I would say that is a big part of who I am. I love reading fictions, historical books, books about technology and even books in Latin, when I feel crazy enough to try to remember the meaning of Latin words. With reading comes studying, and I have always being torn by humanities studies on one side and tech studies on the other.</p>
            <p>Studying for me is a pleasure, and it has always been. In a fast paced world, it is often forgotten how important is to sit for a moment and think, or how is important to understand all the pieces that have brought us here where we are. I see the innovations of web development as the "here and now", and my humanities interests as the "how and why" of contemporary times. Both are really important, and knowing both makes my approach to work quite atypical.</p>
            <p>As the verses of <span className={"fontItalic"}>Upon the Hearth the Fire is Red</span> quoted above, starting my career in web development has been like taking the hidden path that I've never had the courage to take, and that has then changed my life.</p>
        </div>
    </div>    
</div>
)
 
export default About;