import React from "react"
//Import the Footer.css
import FooterStyles from "./Footer.module.css";

const Footer = () => (
<div id="closure">
    <img className={FooterStyles.logo} src={'/logo-white.svg'} alt="Footer logo, white" />
    <p className={FooterStyles.copyright}>Alessio Iervolino &copy; {new Date().getFullYear()}</p>
</div>
)



export default Footer