import React from "react"
import {Link} from "gatsby";
//Import the Header.css
import headerStyles from "./Header.module.css";

const Header = (props) => (
<header className={headerStyles.header}>
    <div className={headerStyles.navContainer}>
        <div className={headerStyles.logoContainer}>
            <Link id="logo" to="/" title="Home Page"><img className={headerStyles.logo} src={'/logo.svg'} alt="Alessio Iervolino - logo" /></Link>
            <Link id="logo-dark" to="/" title="Home Page"><img className={headerStyles.logo} src={'/logo-white.svg'} alt="Alessio Iervolino - logo" /></Link>
        </div>
        <nav className={headerStyles.navigation}>{props.children}</nav>
    </div>
    <h1 className={headerStyles.heroTitle}>ALESSIO IERVOLINO</h1>
    <h2 className={headerStyles.heroSubTitle}>Developer, Creative, Writer, History enthusiast.</h2>
    <img className={headerStyles.heroImage} src={'/dare-to-be-different.svg'} alt="Person who stands out" />
</header>
)

export default Header;