import React from "react"
import contactStyles from "./Contact.module.css";


const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", message: "" };
  }

  handleSubmit = e => {
    document.getElementById("spinner").removeAttribute("hidden");
    document.getElementById("contact-form").style.display = "none";
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then(() => {        
        setTimeout(function(){ 
          document.getElementById("spinner").setAttribute("hidden",""); 
          document.getElementById("success-message").removeAttribute("hidden");
        }, 2500);
          
      })
      .catch( (error) => {
        console.log(error);
        document.getElementById("error-message").removeAttribute("hidden");
        document.getElementById("contact-form").style.display = "block";
      } );

    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, email, message } = this.state;
    return ( 
      <div className={contactStyles.contactContainer}>
        <h2 className={contactStyles.mainTitle}>Say hello</h2>
        <p className={"textCenter"}>Want to discuss a project or talk about the Third Messenian War?</p>
        <div hidden id="spinner" className={contactStyles.spinner}></div>
        <p hidden id="success-message" className={contactStyles.successMessage}>Thank you for your message! Pheidippides himslef is going to deliver your message.</p>
        <p hidden id="error-message" className={contactStyles.errorMessage}>There has been an error submitting the form. Please try again.</p>
        <div className={contactStyles.formContainer}>
      <form id="contact-form" onSubmit={this.handleSubmit} name="contact">
        <label className={contactStyles.label} htmlFor="name">Full name</label>  
        <input type="text" name="name" value={name} onChange={this.handleChange} placeholder="Dionysius of Halicarnassus" required />
        <label className={contactStyles.label} htmlFor="email">Email address</label>
        <input type="email" name="email" value={email} onChange={this.handleChange} placeholder="publius.varus@teutoburg.de" required />
        <label className={contactStyles.label} htmlFor="message">Message</label>
        <textarea name="message" value={message} onChange={this.handleChange} placeholder="Cum multae res in philosophia nequaquam satis adhuc explicatae sint, tum perdifficilis, Brute, quod tu minime ignoras, et perobscura quaestio est de natura deorum, quae et ad cognitionem animi pulcherrima est et ad moderandam religionem necessaria." required />
        <button type="submit">Send message</button> 
      </form>
      </div>
      </div>
    );
  }
}

export default Contact;