import React from "react"
//Import the Header.css
import introStyles from "./Intro.module.css";

const Intro = () => (
    <div id="introContainer" className={introStyles.introContainer}>
        <div className={introStyles.firstContainer}>
            <div className={introStyles.firstParagraphContainer}>
                <p className={introStyles.firstParagraph}>Stand out from the crowd.</p>
            </div>
            <div className={introStyles.secondParagraphContainer}>
                <p className={introStyles.secondParagraph}>Dare to be unique.</p>
            </div>
        </div>
        <div className={introStyles.secondContainer}></div>
        <div className={introStyles.thirdContainer}></div>
    </div>
    
)
    
export default Intro; 