import React from "react"
/*import { Link } from "gatsby"*/
import SEO from "../components/seo"
import Header from "../components/Header"
import Intro from "../components/Intro"
import About from "../components/About"
import Projects from "../components/Projects"
import History from "../components/History"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import scrollTo from 'gatsby-plugin-smoothscroll';


const IndexPage = () => (
  <>
  <SEO title="Home" />  
  <Header>
    <ul>
      <li><a role="button"  tabIndex="0"  onClick={() => scrollTo('#About')} onKeyDown={() => scrollTo('#About')} >About</a></li>
      <li><a role="button"  tabIndex="0"  onClick={() => scrollTo('#Projects')} onKeyDown={() => scrollTo('#Projects')}  >Projects</a></li>
      <li><a role="button"  tabIndex="0"  onClick={() => scrollTo('#History')} onKeyDown={() => scrollTo('#Projects')} >History Facts</a></li>
      <li><span id="theme-switcher"></span></li>
    </ul>
  </Header>
  <About/>
  <Projects/>
  <History/>
  <Contact/>
  <Footer/>
  <Intro />
  </>
)

export default IndexPage 

if (typeof window !== "undefined") {
  setTimeout(function(){
      var body = document.body;
      body.style.overflowY = "scroll";
     // body.classList ? body.classList.add('loaded') : body.className += ' loaded';
  }, 7000);

  setTimeout(function(){
    const themeSwitcher = document.getElementById('theme-switcher');
    themeSwitcher.addEventListener('click', function() {
      document.body.classList.toggle('dark'); 
      localStorage.setItem('theme', document.body.classList);
  })
    if(localStorage.getItem('theme') === "dark") { 
      document.body.classList.toggle(localStorage.getItem('theme'));
    }
    if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.body.classList.toggle('dark'); 
      localStorage.setItem('theme', document.body.classList);
    }
  }, 800);
  
  }
