import React from "react"
//Import the Projects.css
import ProjectsStyles from "./Projects.module.css";

const Projects = () => (
<div id="Projects" className={"row"}>

<div className={ProjectsStyles.leftContainer + " col-lg-4"}>
        <div className={ProjectsStyles.left}>
            <h2 className={ProjectsStyles.mainTitle + " colorDark"}>Projects</h2>
            <img className={ProjectsStyles.heroImage} src={'/projects-and-works.svg'} alt="Person who shows works" />
        </div>
    </div> 

    <div className={ProjectsStyles.paddingFix + " col-lg-8"}>
            <div className={"row"}>
                <div className={[ProjectsStyles.backgroundFirst, ProjectsStyles.projectCard, ProjectsStyles.paddingFix].join(' ') + " col-lg-6"}>
                    <a href="https://heritage.rcpsg.ac.uk" target="_blank" rel="noopener noreferrer">
                    <div className={ProjectsStyles.inner}>
                        <div className={ProjectsStyles.innerTextTop}><p>I</p></div>
                        <div className={ProjectsStyles.innerTextBottom}><p>Heritage - Website</p></div>
                    </div>
                    </a>
                </div>
                <div className={[ProjectsStyles.backgroundSecond, ProjectsStyles.projectCard, ProjectsStyles.paddingFix].join(' ') + " col-lg-6"}>
                    <a href="https://telegram.me/MitologiaNordica_bot?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base%3Bz5jR9BbjRim0RODG0Auggg%3D%3D" target="_blank" rel="noopener noreferrer">
                    <div className={ProjectsStyles.inner}>
                        <div className={ProjectsStyles.innerTextTop}><p>II</p></div>
                        <div className={ProjectsStyles.innerTextBottom}><p>Norse Mythology - Educational Bot</p></div>
                    </div>
                    </a>
                </div>
                <div className={[ProjectsStyles.backgroundThird, ProjectsStyles.projectCard, ProjectsStyles.paddingFix].join(' ') + " col-lg-6"}>
                    <a href="https://olawur.com"  target="_blank" rel="noopener noreferrer">
                    <div className={ProjectsStyles.inner}>
                        <div className={ProjectsStyles.innerTextTop}><p>III</p></div>
                        <div className={ProjectsStyles.innerTextBottom}><p>Olawur - Website</p></div>
                    </div>
                    </a>
                </div>
                <div className={[ProjectsStyles.backgroundFourth, ProjectsStyles.projectCard, ProjectsStyles.paddingFix].join(' ') + " col-lg-6"}>
                    <a href="https://www.amazon.com/Vetrarbreytin-Italian-Alessio-Iervolino-ebook/dp/B01I27IL80" target="_blank" rel="noopener noreferrer">
                    <div className={ProjectsStyles.inner}>
                        <div className={ProjectsStyles.innerTextTop}><p>IV</p></div>
                        <div className={ProjectsStyles.innerTextBottom}><p>Vetrarbreytin - Book</p></div>
                    </div>
                    </a>
                </div>                       
            </div>
        </div>




</div>
)
 
export default Projects; 